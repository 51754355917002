import { Injectable } from '@angular/core';
import { Observable, throwError, from } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NavController } from '@ionic/angular/standalone';

export type Params = {
  [key: string]: string | string[] | number;
};

const dateRegex: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.)?\d{0,8}Z$/;

@Injectable({
  providedIn: 'root',
})
export class HttpClientWrapperService {
  private authSecretKey = 'Bearer Token';

  constructor(
    private httpClient: HttpClient,
    private navControl: NavController,
  ) {}

  private handleError(err, url: string) {
    if (err.status === 401 && !url.includes('/me')) {
      this.navControl.navigateRoot('/login');
    }
    return throwError(() => new HttpErrorResponse(err));
  }

  private async getAuthorizationHeader(): Promise<{ headers: HttpHeaders }> {
    const token: string = sessionStorage.getItem('accessToken');
    return {
      headers: new HttpHeaders({
        Authorization: token ? `Bearer ${token}` : '',
      }),
    };
  }

  public postWithoutAuth<T, ContentType>(url: string, content: ContentType): Observable<T> {
    return this.httpClient.post<T>(url, content)
    .pipe(
      map(response => this.parseDatesInResponse(response))
    );
  }

  public post<T, ContentType>(url: string, content: ContentType): Observable<T> {
    return from(this.getAuthorizationHeader()).pipe(
      switchMap(headers =>
        this.httpClient.post<T>(url, content, headers).pipe(catchError(err => this.handleError(err, url)))
      )
    ).pipe(
      map(response => this.parseDatesInResponse(response))
    );
  }

  public get<T>(url: string, params: Params = {}): Observable<T> {
    return from(this.getAuthorizationHeader()).pipe(
      switchMap(headers =>
        this.httpClient.get<T>(url, { ...headers, params }).pipe(catchError(err => this.handleError(err, url)))
      )
    )
    .pipe(
      map(response => this.parseDatesInResponse(response))
    );
  }

  public put<T, ContentType>(url: string, content: ContentType): Observable<T> {
    return from(this.getAuthorizationHeader()).pipe(
      switchMap(headers =>
        this.httpClient.put<T>(url, content, headers).pipe(catchError(err => this.handleError(err, url)))
      )
    ).pipe(
      map(response => this.parseDatesInResponse(response))
    );
  }

  public delete<T>(url: string): Observable<T> {
    return from(this.getAuthorizationHeader()).pipe(
      switchMap(headers =>
        this.httpClient.delete<T>(url, headers).pipe(catchError(err => this.handleError(err, url)))
      )
    ).pipe(
      map(response => this.parseDatesInResponse(response))
    );
  }

  public deleteFav<T, ContentType>(url: string, content: ContentType): Observable<T> {
    return this.httpClient.delete<T>(url, content)
    .pipe(map(response => this.parseDatesInResponse(response)));
  }

  /**
   * Parses date strings in response to Date objects.
   * @param object The object to parse.
   * @returns The object with parsed dates.
   */
  private parseDatesInResponse<T>(response: T) {
    const object: T = response as T;

    if (Array.isArray(object)) {
      // If the response is an array, parse dates in each object
      return object.map(item => this.parseDates(item)) as T;
    }
        
    // Parse dates in the object
    return this.parseDates(object);
  }

  private parseDates<T>(object: T): T {
    if (!object || typeof object !== 'object') {
      return object; 
    }
    
    Object.keys(object).forEach(key => {
      if (object[key] === null) {
        return;
      }

      if (object[key] instanceof Object) {
        // If the value is an object, parse dates in the object
        object[key] = this.parseDates(object[key]);
      }

      if (typeof object[key] === 'string' && dateRegex.test(object[key])) {
        // Convert string dates to Date objects
        object[key] = new Date(object[key]) as Date;
      }
    })

    return object;
  }
}