import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Charity, CharityFilterRequest } from '../../models/Charity';
import { HttpClientWrapperService } from '../http/http-client-wrapper.service';

@Injectable({
  providedIn: 'root',
})
export class CharityService {
  private readonly baseApiUrl = `${environment.beApiUrl}/api/v1/charities`;
  private currentCharity$: BehaviorSubject<Charity> = new BehaviorSubject<Charity>(null);

  constructor(private httpClient: HttpClientWrapperService) {
    this.initializeCharity();
  }

  private initializeCharity() {
    const charityId: string = sessionStorage.getItem('currentCharityId');
    if (charityId) {
      this.setCurrentCharity(charityId, false);
    }
  }

  getCharities(
    query: string = '',
    charitySegment: string = '',
    state: string = '',
    offset: number = 0,
    limit: number = 0,
    sort: string[] = [],
  ): Observable<Charity[]> {
    const args: CharityFilterRequest = { offset };

    if (charitySegment) {
      args.charitySegment = charitySegment;
    }

    if (state) {
      args.state = state;
    }

    if (query) {
      args.q = query;
    }

    if (limit) {
      args.limit = limit;
    }

    if (sort.length > 0) {
      args.sort = sort;
    }

    return this.httpClient.get<Charity[]>(`${this.baseApiUrl}`, args);
  }

  get charity$(): Observable<Charity> {
    return this.currentCharity$.asObservable();
  }

  setCurrentCharity(id: string, persist: boolean = true): void {
    this.httpClient.get<Charity>(`${this.baseApiUrl}/${id}`).subscribe(
      res => {
        this.currentCharity$.next(res);
        if (persist) {
          sessionStorage.setItem('currentCharityId', id);
        }
      },
      error => console.error('Error fetching charity details:', error)
    );
  }

  getCharity(id: string): Observable<Charity> {
    return this.httpClient.get<Charity>(`${this.baseApiUrl}/${id}`);
  }

  getCharityByEin(ein: string): Observable<Charity> {
    return this.httpClient.get<Charity>(`${this.baseApiUrl}/ein/${ein}`);
  }

  updateCharity(id: string, charity: Charity): Observable<Charity> {
    return this.httpClient.put<Charity, Charity>(`${this.baseApiUrl}/${id}`, charity);
  }

  changeCharityProfilePic(id: number, formData: FormData): Observable<Charity> {
    return this.httpClient.post<Charity, FormData>(
      `${this.baseApiUrl}/${id}/upload-image`,
      formData,
    );
  }
}
