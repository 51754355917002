import { Component, OnInit } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
})
export class AppComponent implements OnInit {

  constructor() {
    defineCustomElements(window);
  }

  ngOnInit() {
    const mode: string = localStorage.getItem('color-mode');
    // console.log(mode);
    if(mode === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }
}
