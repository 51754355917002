import { Component, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { addIcons } from 'ionicons';
import * as icons from 'ionicons/icons';
import { Subscription } from 'rxjs';
import {
  NavController,
  IonHeader,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonText,
  AlertController,
  IonBadge,
  IonLabel,
  IonImg,
  IonSkeletonText,
} from '@ionic/angular/standalone';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { User } from '../../core/models/User';
import { NotificationsService } from 'src/app/core/services/notifications/notifications.service';
import { TabService } from 'src/app/core/services/navigation/tab.service';
import { StateType } from 'src/app/core/models/StateType';
import { ProductsService } from 'src/app/core/services/commerce/products.service';
import { CommonModule } from '@angular/common';

const MAIN_TABS: string[] = [
  '/user-role-tabs/home',
  '/user-role-tabs/discover',
  '/user-role-tabs/commerce',
];

const userTabs: string[] = ['home', 'discover', 'commerce'];

@Component({
  selector: 'app-user-role-tabs',
  templateUrl: './user-role-tabs.component.html',
  styleUrls: ['./user-role-tabs.component.scss'],
  standalone: true,
  imports: [
    IonBadge,
    IonText,
    IonHeader,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonBadge,
    CommonModule,
    IonLabel,
    IonImg,
    IonSkeletonText
  ],
})
export class UserRoleTabsComponent {
  currentTab: string;
  isMainPage: boolean = true;
  loggedInUser: User;
  private readonly subscriptions = new Subscription();
  authService = inject(AuthService);
  notificationCount: number = 0;
  private isNavigating: boolean = false;
  winningBanner: boolean = false;
  imageLoaded: boolean = false;
  private manuallyClickedTab: string | null = null;

  constructor(
    private navController: NavController,
    private router: Router,
    private alertController: AlertController,
    private notificationsService: NotificationsService,
    private tabService: TabService,
    private productsService: ProductsService
  ) {
    addIcons({ ...icons });
    this.getLoggedInUserInfo();
    if (!this.currentTab) this.currentTab = this.tabService.getLastTab('user');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!event.url.startsWith('/user-role-tabs')) return;
        this.isMainPage = !!MAIN_TABS.find(page => page === event.url);
        const state: StateType = this.router.getCurrentNavigation()?.extras.state || {};
        setTimeout(() => {
          this.tabService.trackNavigation('user', this.currentTab, event.urlAfterRedirects, state);
        }, 500);
      }
    });
  }

  getLoggedInUserInfo() {
    this.subscriptions.add(
      this.authService.user$.subscribe(user => {
        if (user) {
          this.loggedInUser = user;
          this.getNotificationCount(user.id);
          this.getWinningProducts(user.id);
          if (!user.avatarUrl || user.avatarUrl === 'test') {
            this.loggedInUser.avatarUrl = 'assets/ic-profile-charity.svg';
          }
        }
      }),
    );
  }

  getNotificationCount(id) {
    this.notificationsService.loadUnreadCountByUserId(id);
    this.subscriptions.add(
      this.notificationsService.userNotificationCount$.subscribe(count => {
        this.notificationCount = count;
      }),
    );
  }

  getWinningProducts(id) {
    this.subscriptions.add(
      this.productsService.getWinningProductsByUserId(id).subscribe({
        next: winners => {
          if(winners.length > 0){
            this.winningBanner = true;
            document.body.classList.add('banner-visible');
          } else {
            this.winningBanner = false;
            document.body.classList.remove('banner-visible');
          }
        }
      })
    )
  }

  onTabClick(tabName: string) {
    this.manuallyClickedTab = tabName;
  }

  handleTabChange(event) {
    const page: string = event.tab;
    if (this.isNavigating) return;
    this.isNavigating = true;

    if (userTabs.find(tab => tab === page)) {
      if (page === this.currentTab) {
        this.tabService.resetTabHistory('user', page);
        this.navController.navigateRoot(`/user-role-tabs/${page}`).finally(() => {
          this.isNavigating = false;
        });
      } else {
        this.currentTab = page;
        const lastTabPage: { url: string; state: StateType } = this.tabService.getLastTabPage(
          'user',
          this.currentTab,
        );
        if (lastTabPage) {
          this.navController
            .navigateRoot(lastTabPage.url, { state: lastTabPage.state || {} })
            .finally(() => {
              this.isNavigating = false;
            });
        } else {
          this.isNavigating = false;
        }
      }
    } else {
      if (!this.currentTab) this.currentTab = this.tabService.getLastTab('user');
      this.isNavigating = false;
    }
  }

  goToWinningItems() {
    this.navController.navigateRoot(`/user-role-tabs/winning-items`).then(() => {
      this.isNavigating = false;
    });
  }

  goToMenuPage() {
    this.navController.navigateForward(['/menu']).then(r => r);
  }

  goToNotificationsPage() {
    this.navController.navigateForward(['/user-role-tabs/notifications']).then(r => r);
  }

  back() {
    if (!this.currentTab) this.currentTab = this.tabService.getLastTab('user');
    const backTabPage: { url: string; state: StateType } = this.tabService.navigateBack(
      'user',
      this.currentTab,
    );
    if (backTabPage) {
      this.navController.navigateBack(backTabPage.url, { state: backTabPage.state || {} });
    } else {
      this.navController.navigateRoot(`/user-role-tabs/${this.currentTab}`);
    }
  }

  onInvite(): void {
    if (navigator.share) {
      navigator
        .share({
          title: 'Check out the Sunny Street app!',
          text: 'I found this great app. You should check it out!',
          url: 'https://sunnystreet.app/',
        })
        .catch(error => {
          console.error('Error sharing', error);
          this.showFallbackInviteOption();
        });
    } else {
      this.showFallbackInviteOption();
    }
  }

  showFallbackInviteOption(): void {
    const shareUrl: string = 'https://sunnystreet.com/';
    navigator.clipboard.writeText(shareUrl).then(() => {
      this.alertController
        .create({
          header: 'Invitation link copied to clipboard',
          buttons: ['OK'],
          cssClass: 'alert-message',
        })
        .then((alert: HTMLIonAlertElement) => {
          alert.present();
        })
        .catch(err => {
          console.error('Failed to copy link to clipboard:', err);
          this.alertController
            .create({
              header: 'Failed to copy the link.',
              buttons: ['OK'],
              cssClass: 'alert-error',
            })
            .then((alert: HTMLIonAlertElement) => {
              alert.present();
            });
        });
    });
  }
}
