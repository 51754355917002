<ion-header class="ion-no-border">
  <ion-toolbar class="main-toolbar">
    <ion-buttons slot="start">
      @if (!isMainPage) {
      <ion-button class="btn-back" (click)="back()" (keydown)="back()" tabindex="{0}">
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
      }
      <ion-text class="ss-logo">Sunny Street</ion-text>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button
        (click)="onInvite()"
        (keydown)="onInvite()"
        tabindex="{0}"
        class="btn-ic-invite"
        fill="clear"
      >
        <ion-icon slot="icon-only" src="/assets/ic-paper-airplane.svg"></ion-icon>
      </ion-button>
      <ion-button (click)="goToNotificationsPage()" class="btn-ic-notification" fill="clear">
        <ion-icon slot="icon-only" name="notifications"></ion-icon>
        @if (notificationCount > 0){
        <ion-badge color="danger" class="icon-badge">{{ notificationCount }}</ion-badge>
        }
      </ion-button>
      <ion-button (click)="goToMenuPage()" class="btn-ic-menu" fill="clear">
        <div class="profile-avatar image-wrapper circle">
          <ion-skeleton-text *ngIf="!imageLoaded" animated></ion-skeleton-text>
          <ion-img 
            alt="avatar" 
            [src]="loggedInUser?.avatarUrl"
            (ionImgDidLoad)="imageLoaded = true"
            class="{{!imageLoaded ? 'hidden' : 'fade-in'}}"
          ></ion-img>
        </div>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div
  *ngIf="winningBanner" 
  class="winningBanner horizonal-cards-heading text-section subsection-title-container"
  tabindex="{0}"
  (keyup)="goToWinningItems()"
  (click)="goToWinningItems()">
  <div>
    <h1>Pending Payment</h1>
    <h5>Some items need your attention</h5>
  </div>
  <ion-text>
    <ion-label>See All</ion-label>
    <ion-icon name="chevron-forward-outline"></ion-icon>
  </ion-text>
</div>

<ion-tabs (ionTabsWillChange)="handleTabChange($event)">
  <ion-tab-bar slot="bottom">
    <ion-tab-button tab="home">
      <ion-icon
        [src]="currentTab === 'home' ? '/assets/ic-trend.svg' : '/assets/ic-trend-outline.svg'"
      ></ion-icon>
    </ion-tab-button>

    <ion-tab-button tab="discover">
      <ion-icon
        [src]="
          currentTab === 'discover'
            ? '/assets/ic-search-filled.svg'
            : '/assets/ic-search-outline.svg'
        "
      ></ion-icon>
    </ion-tab-button>

    <ion-tab-button tab="commerce">
      <ion-icon
        [src]="currentTab === 'commerce' ? '/assets/ic-bag.svg' : '/assets/ic-bag-outline.svg'"
      ></ion-icon>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>
