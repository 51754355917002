import { Injectable, inject } from '@angular/core';
import { Credentials } from 'src/app/core/models/Credentials';
import { environment } from 'src/environments/environment';
import { 
  RefreshToken, 
  Auth, 
  ClientData, 
  PasskeyRegisterData, 
  PasskeyAuthData,
  AuthenticationOptionsResponse,
  RegistrationOptionsResponse,
  Passkey
} from 'src/app/core/models/UserSession';
import { HttpClientWrapperService, Params } from '../http/http-client-wrapper.service';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { User, SignUpUser } from '../../models/User';
import { Buffer } from 'buffer';
import axios from 'axios';
type Base64EncodedBuffer = { bytes?: string };

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly authApiUrl = `${environment.beApiUrl}/api/v1/auth`;
  private readonly userApiUrl = `${environment.beApiUrl}/api/v1/user-profile`;
  private readonly webauthnApiUrl = `${environment.beApiUrl}/api/v1/webauthn`;
  private httpClient = inject(HttpClientWrapperService);
  public userSession$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  authenticated = false;
  title = 'webauthn-test';

  constructor() {
    this.initializeAuth();
  }

  private initializeAuth() {
    const accessToken: string = sessionStorage.getItem('accessToken');
    const username: string = sessionStorage.getItem('username');
    if (accessToken) {
      this.authenticated = true;
      this.userSession$.next(accessToken);
      this.getAuthUser(username);
    } else {
      this.logout();
    }
  }

  private async getAuthUser(username: string) {
    try {
      const usr: User = await this.httpClient
        .get<User>(`${this.userApiUrl}?email=${username}`)
        .toPromise();
      this.authenticated = true;
      this.currentUser$.next(usr);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  }

  get session$(): Observable<string> {
    return this.userSession$.asObservable();
  }

  get user$(): Observable<User> {
    return this.currentUser$.asObservable();
  }

  getUserByUsername(username: string): Promise<User> {
    return this.httpClient.get<User>(`${this.userApiUrl}?email=${username}`).toPromise();
  }

  async login(credentials: Credentials): Promise<void> {
    try {
      const auth: Auth = await this.httpClient
        .postWithoutAuth<Auth, Credentials>(`${this.authApiUrl}/login`, credentials)
        .toPromise();
      sessionStorage.setItem('accessToken', auth.accessToken);
      sessionStorage.setItem('username', credentials.username);
      const usr: User = await this.httpClient
        .get<User>(`${this.userApiUrl}?email=${credentials.username}`)
        .toPromise();
      this.currentUser$.next(usr);
      Sentry.setUser({ email: credentials.username });
    } catch (error) {
      console.error('Login error:', error);
      throw new Error('Login failed. Please try again.');
    }
  }

  async logout(): Promise<void> {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('username');
    this.authenticated = false;
    this.userSession$.next(null);
    this.currentUser$.next(null);
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    try {
      await this.httpClient
        .postWithoutAuth<RefreshToken, Params>(`${this.authApiUrl}/change-password`, {
          oldPassword,
          newPassword,
        })
        .toPromise();
    } catch (err) {
      console.error('Error changing password:', err);
      throw err;
    }
  }

  async signUpNewUser(password: string, user: User): Promise<void> {
    try {
      await this.httpClient
        .postWithoutAuth<void, SignUpUser>(`${this.userApiUrl}/create-user`, {
          profile: user,
          password,
        })
        .toPromise();
    } catch (error) {
      console.error('Signup error:', error);
      throw new Error('Signup failed. Please try again.');
    }
  }

  verifyEmail(userId: number, token: string): Observable<void> {
    return this.httpClient.postWithoutAuth<void, Params>(`${this.authApiUrl}/verify-email`, {
      relatedId: userId,
      token,
    });
  }

  changeProfilePic(id: number, image: File): Promise<User> {
    const formData: FormData = new FormData();
    formData.append('image', image);

    return this.httpClient
      .post<User, FormData>(`${this.userApiUrl}/${id}/upload-image`, formData)
      .toPromise();
  }

  getUserAthority() {
    return this.httpClient.get<Auth>(`${this.authApiUrl}/me`);
  }

  refreshCurrentUser() {
    const username: string = sessionStorage.getItem('username');
    if (username) {
      this.getAuthUser(username);
    }
  }

  // Passkey Authentication
  
  async passkeyRegister(): Promise<void>  {
    try {
      const response: RegistrationOptionsResponse = await this.fetchRegistrationOptions();
      const user: User = await firstValueFrom(this.user$);
      const username: string = user.username;

      const credentialCreationOptions: PublicKeyCredentialCreationOptions = {
        challenge: this.base64UrlToArrayBuffer(response.data.challenge.bytes),
        rp: {
          ...response.data.rp,
          id: this.safeDecodeRpId(response.data.rp.id),
        },
        user: {
          ...response.data.user,
          id: this.base64UrlToArrayBuffer(response.data.user.id.bytes),
          name: response.data.user.name ?? username
        },
        pubKeyCredParams: response.data.pubKeyCredParams,
        timeout: response.data.timeout,
        attestation: response.data.attestation,
        authenticatorSelection: {
          residentKey: 'required',
          userVerification: 'preferred',
          authenticatorAttachment: undefined
        }
      };
      
      const credential: PublicKeyCredential = await navigator.credentials.create({
        publicKey: credentialCreationOptions
      }) as PublicKeyCredential;

      const credentialId: string = this.arrayBufferToBase64(credential.rawId);

      const authenticatorResponse: AuthenticatorAttestationResponse = credential.response as AuthenticatorAttestationResponse;
      const publicKey: ArrayBuffer = authenticatorResponse.getPublicKey();
      const authenticatorData: ArrayBuffer = authenticatorResponse.getAuthenticatorData();
    
      const data: PasskeyRegisterData = {
        rawId: credentialId,
        transports: authenticatorResponse.getTransports(),
        userHandle: this.arrayBufferToBase64(this.toArrayBuffer(credentialCreationOptions.user.id)),
        publicKey: publicKey ? this.arrayBufferToBase64(publicKey) : null,
        authenticatorData: authenticatorData ? this.arrayBufferToBase64(authenticatorData) : null,
        response: {
          id: credential.id,
          type: credential.type,
          attestationObject: this.arrayBufferToBase64(authenticatorResponse.attestationObject),
          clientData: JSON.parse(this.arrayBufferToStr(authenticatorResponse.clientDataJSON))
        }
      }
      const accessToken: string = sessionStorage.getItem('accessToken');

      await axios.post(`${this.webauthnApiUrl}/register`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      const passkeys: Passkey[] = JSON.parse(localStorage.getItem('passkeys') || '[]');

      const passkey: Passkey = {
        key: 'yes',
        username: username,
      }
      passkeys.push(passkey);
      localStorage.setItem('passkeys', JSON.stringify(passkeys));
    } catch (error) {
      console.error('Passkey registration failed:', error);
      throw error;
    }
  }

  async passkeyAuthentication() {
    const response: AuthenticationOptionsResponse = await this.fetchAuthenticationOptions();

    const credentialRequestOptions: PublicKeyCredentialRequestOptions = {
      challenge: this.base64UrlToArrayBuffer(response.data.challenge.bytes),
      timeout: response.data.timeout,
      rpId: response.data.rpId,
      userVerification: response.data.userVerification
    };

    // for local development
    const isLocalhost: boolean = window.location.hostname === 'localhost';
    if (isLocalhost) {
      credentialRequestOptions.rpId = 'localhost';
    }
    const credential: PublicKeyCredential = await navigator.credentials.get({ publicKey: credentialRequestOptions }) as PublicKeyCredential;

    const authenticatorResponse: AuthenticatorAssertionResponse = credential.response as AuthenticatorAssertionResponse;

    const data: PasskeyAuthData = {
      rawId: this.arrayBufferToBase64(credential.rawId),
      response: {
        authenticatorData: this.arrayBufferToBase64(authenticatorResponse.authenticatorData),
        signature: this.arrayBufferToBase64(authenticatorResponse.signature),
        clientData: this.parseClientData(authenticatorResponse.clientDataJSON),
        rawClientData: this.arrayBufferToBase64(authenticatorResponse.clientDataJSON),
        userHandle: authenticatorResponse.userHandle
          ? this.arrayBufferToBase64(authenticatorResponse.userHandle)
          : null,
        id: credential.id,
        type: credential.type
      }
    }

    const auth: Auth = await this.httpClient
      .postWithoutAuth<Auth, typeof data>(`${this.webauthnApiUrl}/authenticate`, data)
      .toPromise();
    sessionStorage.setItem('accessToken', auth.accessToken);

    const authUser: User = await this.httpClient.get<User>(`${this.authApiUrl}/me`).toPromise();
    this.checkNewPasskey(authUser.username)

    const usr: User = await this.httpClient
        .get<User>(`${this.userApiUrl}?email=${authUser.username}`)
        .toPromise();
    sessionStorage.setItem('username', usr.username);

    this.currentUser$.next(usr);
    Sentry.setUser({ email: usr.username});
  }

  checkNewPasskey(username: string) {
    const passkeys: Passkey[] = JSON.parse(localStorage.getItem('passkeys') || '[]');
    const hasPasskey: boolean = passkeys.some(passkey => passkey.username === username);
    if (!hasPasskey) {
      const passkey: Passkey = {
        key: 'yes',
        username: username,
      }
      passkeys.push(passkey);
    }
  }

  async fetchRegistrationOptions() {
    const accessToken: string = sessionStorage.getItem('accessToken');
    return await axios.get(`${this.webauthnApiUrl}/registration/options`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }

  async fetchAuthenticationOptions() {
    return await axios.get(`${this.webauthnApiUrl}/authentication/options`);
  }

  base64UrlToArrayBuffer(base64url: string): Uint8Array {
    const base64: string = base64url
      .replace(/-/g, '+')
      .replace(/_/g, '/')
      .padEnd(base64url.length + (4 - base64url.length % 4) % 4, '=');
    return Uint8Array.from(atob(base64), c => c.charCodeAt(0));
  }
    
  private safeDecodeRpId(input: Base64EncodedBuffer | string): string {
    const isLocalhost: boolean = window.location.hostname === 'localhost';
    if (isLocalhost) {
      return 'localhost';
    }

    if (typeof input === 'string') return input;
    if (input?.bytes) {
      return this.arrayBufferToStr(this.base64UrlToArrayBuffer(input.bytes));
    }
    throw new Error('rp.id format is not recognized');
  }

  arrayBufferToBase64(arrayBuffer: ArrayBuffer): string {
    const str: string = window.btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)));
    return str;
  }

  arrayBufferToStr(arrayBuffer: ArrayBuffer): string {
    const buffer: Buffer = Buffer.from(arrayBuffer);
    return buffer.toString('utf-8');
  }

  parseClientData(clientDataJSON: ArrayBuffer): ClientData {
    const clientDataStr: string = this.arrayBufferToStr(clientDataJSON);
    const json: ClientData = JSON.parse(clientDataStr) as ClientData;
    return json;
  }

  toArrayBuffer(input: BufferSource): ArrayBuffer {
    return input instanceof ArrayBuffer ? input : input.buffer;
  }
}
