import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Product, ProductWinner } from 'src/app/core/models/Product';
import { HttpClientWrapperService } from '../http/http-client-wrapper.service';
import { Observable, map, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private readonly productsApiUrl = `${environment.beApiUrl}/api/v1/products`;
  private readonly productWinnersApiUrl = `${environment.beApiUrl}/api/v1/product-winners`;
  private charityProductsSubject = new BehaviorSubject<Product[]>([]);
  charityProducts$ = this.charityProductsSubject.asObservable();
  private auctionProductsSubject = new BehaviorSubject<Product[]>([]);
  auctionProducts$ = this.auctionProductsSubject.asObservable();

  constructor(private httpClient: HttpClientWrapperService) {}

  getAllProducts(): Observable<Product[]> {
    return this.httpClient.get<Product[]>(this.productsApiUrl);
  }
  
  getAllMarketplaceProducts(): Observable<Product[]> {
    return this.httpClient.get<Product[]>(this.productsApiUrl).pipe(
      map(products => products.filter(product => product.acceptsBids === false))
    );
  }

  loadMarketplaceProductsByCharityId(charityId: number): void {
    this.getMarketplaceProductsByCharityId(charityId).subscribe({
      next: (products) => this.charityProductsSubject.next(products),
      error: (err) => console.error('Failed to fetch products:', err)
    });
  }

  getMarketplaceProductsByCharityId(charityId: number): Observable<Product[]> {
    return this.httpClient.get<Product[]>(this.productsApiUrl).pipe(
      map(products => products.filter(product =>
        product.acceptsBids === false && product.charityId === charityId
      ))
    );
  }

  loadProductsByAuctionId(auctionId: string): void {
    this.getProductsByAuctionId(auctionId).subscribe({
      next: (products) => this.auctionProductsSubject.next(products),
      error: (err) => console.error('Failed to fetch products:', err)
    })
  }
  
  getProductsByAuctionId(auctionId: string): Observable<Product[]> {
    return this.httpClient.get<Product[]>(`${this.productsApiUrl}?auctionId=${auctionId}`);
  }
  
  getProductByProductId(productId: string): Observable<Product> {
    return this.httpClient.get<Product>(`${this.productsApiUrl}/${productId}`);
  }

  deleteProductByProductId(productId: string): Observable<Product> {
    return this.httpClient.delete<Product>(`${this.productsApiUrl}/${productId}`);
  }

  postProduct(formData: FormData): Observable<Product> {
    return this.httpClient.post<Product, FormData>(`${this.productsApiUrl}`, formData);
  }

  editProduct(product: FormData, id: string): Observable<Product> {
    return this.httpClient.put<Product, FormData>(`${this.productsApiUrl}/${id}`, product);
  }

  getFavorites(): Observable<Product[]> {
    return this.httpClient.get<Product[]>(`${this.productsApiUrl}/favorites`);
  }

  postFavoriteByProductId(id: number): Observable<void> {
    return this.httpClient.post<void, number>(`${this.productsApiUrl}/${id}/favorite`, id);
  }

  deleteFavoriteByProductId(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.productsApiUrl}/${id}/favorite`);
  }

  getActiveBids(): Observable<Product[]> {
    return this.httpClient.get<Product[]>(`${this.productsApiUrl}/active-bids`);
  }

  getWinningProductsByUserId(userId: string): Observable<ProductWinner[]> {
    return this.httpClient.get<ProductWinner[]>(`${this.productWinnersApiUrl}?userId=${userId}`);
  }

  getPendingWinningProductsByCharityId(charityId: string): Observable<ProductWinner[]> {
    return this.httpClient.get<ProductWinner[]>(`${this.productWinnersApiUrl}?charityId=${charityId}`);
  }

  getUnpaidWinningProductsByCharityId(charityId: string, status: string): Observable<ProductWinner[]> {
    return this.httpClient.get<ProductWinner[]>(`${this.productWinnersApiUrl}?charityId=${charityId}&status=${status}`);
  }

  getUnpaidWinningProductsByUserId(userId: string, status: string): Observable<ProductWinner[]> {
    return this.httpClient.get<ProductWinner[]>(`${this.productWinnersApiUrl}?userId=${userId}&status=${status}`);
  }

  getWinningProductByProductId(productId: string): Observable<ProductWinner> {
    return this.httpClient.get<ProductWinner>(`${this.productWinnersApiUrl}/product/${productId}`);
  }

  postDuplicateProduct(productId: number, auctionId: number): Observable<Product> {
    return this.httpClient.post<Product, number>(`${this.productsApiUrl}/${productId}/duplicate?newAuctionId=${auctionId}`, auctionId);
  }

  deleteWinningProductByProductId(productId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.productWinnersApiUrl}/product/${productId}`);
  }

  postSecondChangeOffer(productId: number, userId: number): Observable<ProductWinner> {
    return this.httpClient.post<ProductWinner, number>(`${this.productWinnersApiUrl}/handle-unpaid/?userId=${userId}&productId=${productId}`, productId);
  }
}
